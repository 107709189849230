import { Box, Typography, Stack } from "@mui/material";
import ChosenInvestmentTable from "../investment-choices/ChosenInvestmentTable";
import FinalSummaryBoxHeader from "./FinalSummaryBoxHeader";
import PersonalDetailsTable from "./PersonalDetailsTable";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FormHeader from "../header/FormHeader";
import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import FooterButtons from "../footer/FooterButtons";
import useStepper from "../../../../../hooks/useStepper";
import { useMutation, useLazyQuery } from "@apollo/client";
import { getTax } from "../../../../../graphql/queries/getTax";
import Loader from "../../../../generic-components/loader";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { updatedStepNumber } from "../../../../../graphql/mutations/updateStepNumber";
import moment from "moment";
import { setForm } from "../../../../../reducers/formReducer";
import { getInvestmentOptions } from "../../../../../graphql/queries/investmentOptions";
import {
  transformTempInvestmentChoices,
  currencyFormatter,
} from "../../../../../helpers";
import { getTempPlan } from "../../../../../graphql/queries/tempPlan";

const FinalSummary = ({
  form,
  scheme,
  navigateToSharedCostAvc,
  navigateToInvestmentChoices,
  navigateToPersonalDetails,
}) => {
  const dispatch = useDispatch();
  const [sharedCostSaving, setSharedCostSaving] = useState(0);
  const [tempPlan, setTempPlan] = useState({});

  const {
    activeStep,
    btnClicked,
    setSavedToast,
    setSaveError,
    setErrorToast,
    handleNext,
    setIsLoadingNext,
    setIsLoadingSave,
    loading,
    setInitialValues,
    setFormValues,
    setActiveStep,
    setInvestmentFormIndex,
    setSharedFormIndex,
  } = useStepper();

  const avcAmount = useMemo(
    () =>
      tempPlan.additional_avc_amount
        ? Number(tempPlan.total_avc_amount_requested || 0) +
          tempPlan.additional_avc_amount
        : Number(tempPlan.total_avc_amount_requested || 0),
    [tempPlan]
  );

  const [amount_integer, amount_fraction] = currencyFormatter(avcAmount);

  const [shared_cost_amount_integer, shared_cost_amount_fraction] =
    currencyFormatter(avcAmount - sharedCostSaving);

  const [getSavings, { loading: taxLoading }] = useLazyQuery(getTax, {
    onCompleted: (data) => {
      setSharedCostSaving(
        data?.plan_savings?.income_tax_saving_on_salary_sacrifice +
          data?.plan_savings?.monthly_nic_saving_on_salary_sacrifice
      );
    },
    onError: (error) => {
      setSaveError(error);
      setErrorToast(true);
    },
  });

  const [investmentChoicesOptions, setInvestmentChoicesOptions] = useState([]);

  const [updateApplication] = useMutation(updatedStepNumber);

  const [fetchInvestmentOptions, { loading: isLoading }] = useLazyQuery(
    getInvestmentOptions,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setInvestmentChoicesOptions(
          data.investment_options.map((choice) => ({
            name: choice.display_name,
            value: choice.id,
          }))
        );
      },
      onError: (error) => {
        setSaveError(error);
        setErrorToast(true);
      },
    }
  );

  const [fetchTempPlan, { loading: planLoading }] = useLazyQuery(getTempPlan, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const plan = data.temp_plans.find((tempPlan) => tempPlan) || {};

      setTempPlan({
        ...plan,
        investmentChoices: transformTempInvestmentChoices(
          plan.temp_plan_investments
        ),
      });

      setFormValues((prevValues) => ({
        ...prevValues,
        ...plan,
        investmentChoices: transformTempInvestmentChoices(
          plan.temp_plan_investments
        ),
      }));

      setInitialValues(plan);

      if (plan.provider_id && plan.pension_fund_id) {
        fetchInvestmentOptions({
          variables: {
            pensionId: plan.pension_fund_id,
            providerId: plan.provider_id,
            lifestyle: plan.lifestyling_option,
          },
        });
      } else {
        setActiveStep(2);
        setInvestmentFormIndex(3);
        setSaveError({
          message: `Please provide the pension scheme and provider`,
          overRideCustom: true,
        });
        setErrorToast(true);
      }

      const avcAmount = plan.additional_avc_amount
        ? Number(plan.total_avc_amount_requested || 0) +
          plan.additional_avc_amount
        : Number(plan.total_avc_amount_requested || 0);

      if (plan && plan.annual_salary && plan.avc_interval && avcAmount) {
        getSavings({
          variables: {
            annual_salary: plan.annual_salary,
            total_avc_amount_requested: avcAmount,
            avc_interval: plan.avc_interval,
            scheme_id: scheme.scheme_id,
            add_ni_savings_onto_avc: false,
          },
        });
      } else {
        setActiveStep(1);
        setSharedFormIndex(1);
        setSaveError({
          message: `Please provide the complete required details.`,
          overRideCustom: true,
        });
        setErrorToast(true);
      }
    },
    onError: (error) => {
      setSaveError(error);
      setErrorToast(true);
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (form.id) {
      fetchTempPlan({
        variables: {
          id: form.id,
        },
      });
    }
  }, [form]);

  const queryLoaders = useMemo(
    () => planLoading || loading || taxLoading || isLoading,
    [taxLoading, loading, isLoading, planLoading]
  );

  return (
    <Box className="final-summary">
      <Formik
        initialValues={{}}
        onSubmit={() => {
          if (tempPlan.id) {
            if (btnClicked === "save_and_next") {
              setIsLoadingNext(true);
            } else if (btnClicked === "save") {
              setIsLoadingSave(true);
            }
            updateApplication({
              variables: {
                id: tempPlan.id,
                step_number:
                  btnClicked === "save" ? activeStep + 5 : activeStep + 6,
                updated_at: moment().format(),
              },
              onCompleted: (data) => {
                if (btnClicked === "save") {
                  setSavedToast(true);
                }
                dispatch(
                  setForm({
                    ...tempPlan,
                    ...data.update_temp_plans.returning[0],
                  })
                );
                if (btnClicked === "save_and_next") {
                  handleNext();
                }
                setIsLoadingNext(false);
                setIsLoadingSave(false);
              },
              onError: (error) => {
                setIsLoadingNext(false);
                setIsLoadingSave(false);
                setSaveError(error);
                setErrorToast(true);
              },
            });
          } else {
            setSaveError({ type: "error" });
            setErrorToast(true);
          }
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {queryLoaders ? (
              <Box className="mt-30">
                <Loader />
              </Box>
            ) : (
              <>
                <Box className="application-page-container">
                  <FormHeader heading="Final Summary" amount={avcAmount} />
                  <Box className="mt-30">
                    <FinalSummaryBoxHeader
                      heading="Your personal details:"
                      handleChange={navigateToPersonalDetails}
                    />
                    <PersonalDetailsTable tempPlan={tempPlan} />
                  </Box>
                  <Box className="amount-container mt-30">
                    <FinalSummaryBoxHeader
                      heading={`Amount from your ${tempPlan?.avc_interval?.toLowerCase()} pay:`}
                      handleChange={navigateToSharedCostAvc}
                    />
                    <Typography className="amount-text stepper-content mt-10">
                      <strong>
                        <span>{shared_cost_amount_integer}</span>
                        <span className="fraction-text">
                          .{shared_cost_amount_fraction}
                        </span>
                      </strong>
                    </Typography>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="baseline"
                      paddingLeft="1rem"
                      paddingRight="1rem"
                      className="mt-10"
                    >
                      <strong className="success-text no-wrap-text">
                        <span>{amount_integer}</span>
                        <span className="fraction-text">
                          .{amount_fraction}
                        </span>
                      </strong>
                      <Stack direction="column">
                        <Typography className="thanks-text" component="span">
                          will go into your pot, thanks to relief on Income Tax
                          and National Insurance estimated*.
                        </Typography>
                        <Typography
                          className="thanks-text"
                          component="span"
                          mt="3px"
                        >
                          This is the amount that will be shown on your payslip,
                          but the actual cost to you is still just &nbsp;
                          <Typography component="span">
                            <span>{shared_cost_amount_integer}</span>
                            <span className="fraction-text">
                              .{shared_cost_amount_fraction}
                            </span>
                          </Typography>
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <Box className="mt-30">
                      <FinalSummaryBoxHeader
                        heading="Chosen Investments:"
                        handleChange={navigateToInvestmentChoices}
                      />
                      <ChosenInvestmentTable
                        values={tempPlan}
                        investmentOptions={investmentChoicesOptions}
                      />
                    </Box>
                  )}
                  <Box className="note-container mt-30">
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      className="note-heading-container"
                    >
                      <ErrorOutlineIcon
                        className="warning-icon"
                        fontSize="small"
                      />
                      <Typography className="note-heading">
                        Please Note :
                      </Typography>
                    </Stack>
                    <Typography className="note-detail">
                      Please double check all of this information is correct
                      before proceeding.
                    </Typography>
                  </Box>
                  <Typography className="footer-text  mt-18">
                    *based on the information you have provided us in this
                    application. Final costs may vary depending on your
                    individual circumstances.
                  </Typography>
                </Box>
                <FooterButtons />
              </>
            )}
          </form>
        )}
      </Formik>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    form: state.form.form,
    scheme: state.scheme.scheme,
    formSharedTitle: state.multiForm.formSharedTitle,
  };
};

FinalSummary.propTypes = {
  form: PropTypes.object,
  scheme: PropTypes.object,
  navigateToSharedCostAvc: PropTypes.func,
  navigateToInvestmentChoices: PropTypes.func,
  navigateToPersonalDetails: PropTypes.func,
  user: PropTypes.object,
  formSharedTitle: PropTypes.string,
};

export default connect(mapStateToProps)(FinalSummary);
