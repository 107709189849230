import {
  Step,
  StepLabel,
  Stepper as MuiStepper,
  useMediaQuery,
  Link,
  Stack,
} from "@mui/material";
import PropTypes from "prop-types";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import { Check } from "@mui/icons-material";
import useStepper from "../../../hooks/useStepper";
import { useToast } from "../../../hooks/useToast";
import { useState } from "react";
import { TRACKING_NAF_OPTIONS } from "../../../constants";
import useUserEvent from "../../../hooks/useUserEvent";
import { saveTempPlan } from "../../../graphql/mutations/saveTempPlan";
import moment from "moment/moment";
import { connect, useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import {
  createInvestmentChoice,
  deleteInvestmentChoice,
} from "../../../graphql/mutations/investmentChoice";
import { setForm } from "../../../reducers/formReducer";

const Stepper = ({ steps, tempPlan, form, isValidForm, submitForm }) => {
  const tablet = useMediaQuery("(max-width:900px)");
  const [open, setOpen] = useState(false);
  const { showToast } = useToast();
  const { userTrackingMutation } = useUserEvent();

  const {
    activeStep,
    setActiveStep,
    completedStep,
    setCompletedStep,
    setSubmitFormIndex,
    sharedFormIndex,
    investmentFormIndex,
    submitFormIndex,
    trackingCode,
    personalDetails,
    setSaveError,
    setErrorToast,
    setFormValues,
    isLoadingSave,
    isLoadingNext,
  } = useStepper();

  const dispatch = useDispatch();

  const [updateApplication] = useMutation(saveTempPlan);
  const [tempInvestmentChoice] = useMutation(createInvestmentChoice);
  const [deleteTempInvestment] = useMutation(deleteInvestmentChoice);

  const handleUserEvent = (event, stepIndex) => {
    const { target } = event;
    const stepLabel = target.textContent?.toString().trim();
    const fieldName = stepLabel
      ? `Step: ${stepLabel}`
      : `Step: ${stepIndex + 1}`;

    userTrackingMutation({
      variables: {
        ...TRACKING_NAF_OPTIONS,
        field_name: fieldName,
        field_value: "stepper step",
        avc_track_code: trackingCode,
      },
    });
  };

  const handleStepClick = (stepIndex) => {
    const isStepCompleted = completedStep.includes(stepIndex);
    const isStepValid =
      stepIndex <= Math.max(...completedStep) || isStepCompleted;

    if ((isStepValid && isValidForm) || stepIndex < activeStep) {
      setCompletedStep([...completedStep, stepIndex]);

      switch (stepIndex) {
        case 0:
          setActiveStep(0);
          break;
        case 1:
          setActiveStep(
            !!sharedFormIndex || sharedFormIndex === 1 || sharedFormIndex === 2
              ? 1
              : 0
          );
          break;
        case 2:
          setActiveStep(
            !!investmentFormIndex ||
              investmentFormIndex === 1 ||
              investmentFormIndex === 2
              ? 2
              : 1
          );
          break;
        case 3:
          setActiveStep(
            submitFormIndex === 1 ||
              submitFormIndex === 2 ||
              submitFormIndex === 3
              ? 3
              : 2
          );
          setSubmitFormIndex(1);
          break;
        default:
          break;
      }
    } else {
      submitForm && submitForm();
      setOpen(true);
    }
  };

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: tablet ? 60 : 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(14,194,14) 0%,rgb(14,194,14) 50%,rgb(14,194,14) 100%)",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(14,194,14) 0%,rgb(14,194,14) 50%,rgb(14,194,14) 100%)",
      },
    },

    [`& .${stepConnectorClasses.line}`]: {
      height: tablet ? "0.1rem" : "5rem",
      width: tablet ? "100%" : "0.1rem",
      border: 0,
      backgroundColor: theme.palette.mode === "dark" ? "green" : "white",
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "white",
    zIndex: 1,
    color: "#fff",
    width: "27px",
    height: "27px",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: tablet && "10px",
    ...(ownerState.active && {
      backgroundImage:
        "linear-gradient( 136deg, rgb(14,194,14) 0%, rgb(14,194,14) 50%, rgb(14,194,14) 100%)",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
      backgroundImage:
        "linear-gradient( 136deg, rgb(14,194,14) 0%, rgb(14,194,14) 50%, rgb(14,194,14) 100%)",
    }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <Check fontSize="1rem" />,
      2: <Check fontSize="1rem" />,
      3: <Check fontSize="1rem" />,
      4: <Check fontSize="1rem" />,
      5: <Check fontSize="1rem" />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
        sx={{ curser: "pointer" }}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  const createTempInvestment = () => {
    const createInvestmentChoices = tempPlan.investmentChoices.map((choice) => {
      if (choice.name) {
        return {
          investment_option_id: choice.name,
          temp_plan_id: form.id,
          specialist_value: choice.percentage,
          default_option: "true",
          created_at: moment().format(),
          updated_at: moment().format(),
        };
      }
    });

    if (
      Array.isArray(createInvestmentChoices) &&
      createInvestmentChoices.length &&
      createInvestmentChoices.filter(Boolean).length
    ) {
      tempInvestmentChoice({
        variables: {
          investmentOptions: createInvestmentChoices.filter(Boolean),
        },
        onCompleted: () => {
          dispatch(setForm({ investmentChoices: tempPlan.investmentChoices }));
          setFormValues((prevValues) => ({
            ...prevValues,
            investmentChoices: tempPlan.investmentChoices,
          }));
        },
        onError: (error) => {
          setSaveError(error);
          setErrorToast(true);
        },
      });
    }
  };

  const handleSave = (stepIndex) => {
    const isStepCompleted = completedStep.includes(stepIndex);
    const isStepValid =
      stepIndex <= Math.max(...completedStep) || isStepCompleted;

    if ((isStepValid && isValidForm) || stepIndex < activeStep) {
      setFormValues(tempPlan);
      updateApplication({
        variables: {
          id: form.id,
          title: tempPlan.title,
          gender: tempPlan.gender,
          employee_number: tempPlan?.employee_number?.trim(),
          referral_code: tempPlan.referral_code,
          delivery_address1: tempPlan.address1,
          delivery_address2: tempPlan.address2,
          delivery_county: tempPlan.county,
          date_of_birth:
            tempPlan.date_of_birth === "Invalid date"
              ? null
              : tempPlan.date_of_birth,
          email: personalDetails.email,
          first_name: tempPlan.first_name,
          last_name: tempPlan.last_name,
          mobile_number: tempPlan.mobile_number,
          ni_number: tempPlan.ni_number,
          delivery_postcode: tempPlan.postcode,
          delivery_town: tempPlan.town,
          telephone_number: tempPlan.mobile_number,
          total_avc_amount_requested: tempPlan.avcAmount || null,
          annual_salary: parseFloat(tempPlan.income),
          hours_per_week: tempPlan.hours_per_week || null,
          max_contribution_amount: tempPlan.max_contribution_amount || null,
          creation_reason_id:
            tempPlan.planReason === -1 ? 0 : tempPlan.planReason,
          creation_reason_other: tempPlan.otherReason,
          previous_amount_added: tempPlan.previous_amount_added || null,
          avc_interval: tempPlan.incomeFrequency,
          additional_avc_amount: tempPlan.additional_avc_amount,
          terms_acknowledged: tempPlan.termCondition,
          signatures: tempPlan.signature,
          prudential_declaration: tempPlan.prudentialConsent,
          nhs_pension_scheme_benefits: tempPlan.pensionSchemeBenefits,
          flexibly_accessed_any_pensions: tempPlan.accessedPensionBtn,
          lifestyling_option: tempPlan.lifestyleCheckbox,
          pension_fund_id: tempPlan.pensionFund || null,
          provider_id: tempPlan.provider || null,
          retirement_age: parseInt(tempPlan.retirementAge, 10) || null,
          pension_age: tempPlan.pensionAge || null,
          choosen_relevant_investment_option: tempPlan.RnUCheckbox || false,
          having_previous_employer_plan: tempPlan.previousEmployerPlan || false,
          combining_with_this_employer:
            tempPlan.combiningWithThisEmployer || false,
          keep_separate_with_employer:
            tempPlan.keepSeparateWithEmployer || false,
          triggered_the_mpaa: tempPlan.triggeredTheMpaa || false,
          mpaa_date:
            tempPlan.triggeredTheMpaa && tempPlan.MPAAdate
              ? tempPlan.MPAAdate
              : null,
          flexibly_accessed_date:
            tempPlan.accessedPensionBtn && tempPlan.flexiblyAccessedDate
              ? tempPlan.flexiblyAccessedDate
              : null,
          investment_advice: tempPlan.confirmCheckbox,
          updated_at: moment().format(),
        },
        onCompleted: () => {
          if (activeStep === 2 && stepIndex === 3) {
            handleInvestmentChoices();
          }
        },
        onError: (error) => {
          setSaveError(error);
          setErrorToast(true);
        },
      });
    }
  };

  const handleInvestmentChoices = () => {
    if (
      Array.isArray(form.investmentChoices) &&
      form.investmentChoices.length &&
      !tempPlan.investmentChoices.length
    ) {
      deleteTempInvestment({
        variables: {
          temp_plan_id: form.id,
        },
        onError: (error) => {
          setSaveError(error);
          setErrorToast(true);
        },
      });
    } else if (
      Array.isArray(tempPlan.investmentChoices) &&
      tempPlan.investmentChoices.length
    ) {
      deleteTempInvestment({
        variables: {
          temp_plan_id: form.id,
        },
        onCompleted: () => createTempInvestment(),
        onError: (error) => {
          setSaveError(error);
          setErrorToast(true);
        },
      });
    }
  };

  return (
    <>
      {showToast(
        "Please complete existing steps before proceeding.",
        open,
        setOpen,
        "error",
        true
      )}
      <MuiStepper
        activeStep={activeStep}
        orientation={`${tablet ? "horizontal" : "vertical"}`}
        connector={<ColorlibConnector />}
        className="stepper"
        alternativeLabel={tablet}
      >
        {steps.map((step, index) => {
          const labelProps = {};
          return (
            <Step key={index}>
              <StepLabel
                {...labelProps}
                disabled={isLoadingSave || isLoadingNext}
                StepIconComponent={ColorlibStepIcon}
                onClick={(e) => {
                  if (isLoadingSave || isLoadingNext) {
                    return;
                  }

                  handleStepClick(index);
                  handleUserEvent(e, index);
                  handleSave(index);
                }}
              >
                <Stack direction="column" justifyContent="flex-end">
                  <Link
                    className="stepper-label stepper-detail"
                    underline="none "
                  >
                    Step {step.id}
                  </Link>
                  {!tablet && (
                    <Link className="stepper-detail" underline="none ">
                      {step.label}
                    </Link>
                  )}
                </Stack>
              </StepLabel>
            </Step>
          );
        })}
      </MuiStepper>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    form: state.form.form,
    organisation: state.organisation.organisation,
    user: state.user.user,
    tempPlan: state.tempPlan.tempPlan,
    isValidForm: state.tempPlan.isValid,
    submitForm: state.tempPlan.submitForm,
  };
};

Stepper.propTypes = {
  steps: PropTypes.array,
  active: PropTypes.number,
  completed: PropTypes.number,
  className: PropTypes.string,
  icon: PropTypes.string,
  tempPlan: PropTypes.object,
  isValidForm: PropTypes.bool,
  submitForm: PropTypes.func,
  form: PropTypes.object,
};

export default connect(mapStateToProps)(Stepper);
