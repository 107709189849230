import { gql } from "@apollo/client";

export const cancelMyPlan = gql`
  mutation cancelMyPlan(
    $plan_id: Int!
    $cancellation_reason_id: Int!
    $cancellation_reason_other: String
  ) {
    cancel_my_plan(
      plan_id: $plan_id
      cancellation_reason_id: $cancellation_reason_id
      cancellation_reason_other: $cancellation_reason_other
    ) {
      message
    }
  }
`;

export const amendMyPlan = gql`
  mutation amendMyPlan(
    $plan_id: Int!
    $total_avc_amount_requested: Float!
    $avc_track_code: String!
    $amend_reason_id: Int!
    $amend_reason_other: String
    $first_name: String
    $last_name: String
    $mobile_number: String
    $telephone_number: String
    $title: String
    $delivery_address1: String
    $delivery_address2: String
    $delivery_town: String
    $delivery_county: String
    $delivery_postcode: String
    $date_of_birth: String
  ) {
    amend_my_plan(
      plan_id: $plan_id
      total_avc_amount_requested: $total_avc_amount_requested
      avc_track_code: $avc_track_code
      amend_reason_id: $amend_reason_id
      amend_reason_other: $amend_reason_other
      first_name: $first_name
      last_name: $last_name
      mobile_number: $mobile_number
      telephone_number: $telephone_number
      title: $title
      delivery_address1: $delivery_address1
      delivery_address2: $delivery_address2
      delivery_town: $delivery_town
      delivery_county: $delivery_county
      delivery_postcode: $delivery_postcode
      date_of_birth: $date_of_birth
    ) {
      id
      message
    }
  }
`;

export const updatePlanDetails = gql`
  mutation updatePlanDetails(
    $plan_id: bigint!
    $hours_per_week: Float
    $annual_salary: float8
  ) {
    update_plans(
      _set: { hours_per_week: $hours_per_week, annual_salary: $annual_salary }
      where: { id: { _eq: $plan_id } }
    ) {
      returning {
        annual_salary
        hours_per_week
      }
    }
  }
`;
