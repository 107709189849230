import { gql } from "@apollo/client";

export const getAvcCalculation = gql`
  query AvcCalculation(
    $annual_salary: Float
    $organisation_id: Int
    $avc_deduction: Float
    $years: Int
  ) {
    shared_avc_calculation(
      annual_salary: $annual_salary
      organisation_id: $organisation_id
      avc_deduction: $avc_deduction
      years: $years
    ) {
      total_pot
    }
  }
`;
