import { gql } from "@apollo/client";

export const updateSharedCostForm = gql`
  mutation updateSharedCostForm(
    $id: Int!
    $total_avc_amount_requested: float8!
    $annual_salary: float8!
    $hours_per_week: float8!
    $max_contribution_amount: float8!
    $avc_interval: String
    $additional_avc_amount: Int
    $previous_amount_added: float8!
    $contribution_amount_updated: Boolean!
    $step_number: Int!
    $referral_code: String
  ) {
    update_temp_plans(
      _set: {
        total_avc_amount_requested: $total_avc_amount_requested
        annual_salary: $annual_salary
        avc_interval: $avc_interval
        hours_per_week: $hours_per_week
        previous_amount_added: $previous_amount_added
        contribution_amount_updated: $contribution_amount_updated
        max_contribution_amount: $max_contribution_amount
        additional_avc_amount: $additional_avc_amount
        step_number: $step_number
        referral_code: $referral_code
      }
      where: { id: { _eq: $id } }
    ) {
      returning {
        total_avc_amount_requested
        annual_salary
        hours_per_week
        previous_amount_added
        contribution_amount_updated
        max_contribution_amount
        additional_avc_amount
        step_number
        avc_interval
        referral_code
      }
    }
  }
`;
