import { Box, Dialog, DialogTitle, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import TextInput from "../../../../../generic-components/input-text";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import IconInputField from "../../../../../generic-components/icon-inputfield";
import {
  floatRegex,
  TRACKING_MANAGE_MY_SHARED_COST_OPTIONS,
} from "../../../../../../constants";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import DateInput from "../../../../../generic-components/date-input";
import moment from "moment";
import LoadingButton from "../../../../../generic-components/button";
import IconButton from "../../../../../generic-components/icon-btn";
import { updateUserInfo } from "../../../../../../graphql/mutations/personalDetails";
import { useMutation } from "@apollo/client";
import ScrollToFieldError from "../../../../../generic-components/scrollToFieldError";
import { requiredToAmendValidationSchema } from "../../../../../../schema";
import { useState } from "react";
import useStepper from "../../../../../../hooks/useStepper";
import { updatePlanDetails } from "../../../../../../graphql/mutations/manageMySharedCost";
import { useDispatch } from "react-redux";
import { setUser } from "../../../../../../reducers/userReducer";
import usePlan from "../../../../../../hooks/usePlan";

const RequiredDetailsDialog = ({
  open,
  onClose,
  initialValues,
  handleBack,
}) => {
  const { plans, setPlans } = usePlan();
  const { setSaveError, setErrorToast, setPersonalDetails, personalDetails } =
    useStepper();

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [updateUserDetails] = useMutation(updateUserInfo);
  const [updateActivePlan] = useMutation(updatePlanDetails);

  const handleError = (error) => {
    setErrorToast(true);
    setSaveError(error);
    setIsLoading(false);
  };

  const handleUpdatePlanDetails = (values) => {
    setIsLoading(true);
    updateUserDetails({
      variables: {
        date_of_birth: values.date_of_birth,
      },
      onCompleted: () => {
        const variables = {
          plan_id: parseInt(values.plan_id),
          hours_per_week: parseFloat(values.hours_per_week),
          annual_salary: parseFloat(values.annual_salary),
        };

        dispatch(setUser({ date_of_birth: values.date_of_birth }));
        setPersonalDetails({
          ...personalDetails,
          date_of_birth: values.date_of_birth,
        });

        updateActivePlan({
          variables: variables,
          onCompleted: () => {
            const existingPlan = plans.find((plan) => plan);
            setPlans([{ ...existingPlan, ...variables }]);
            onClose();
            setIsLoading(false);
          },
          onError: (error) => {
            handleError(error);
          },
        });
      },
      onError: (error) => {
        handleError(error);
      },
    });
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="event-confirmation-dialog"
    >
      <DialogTitle className="dialog-header">
        <Typography className="dialog-heading">Required Details</Typography>
      </DialogTitle>
      <Box className="p-lr-30">
        <Typography className="mt-18">
          Please provide the following details to amend your plan.
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={requiredToAmendValidationSchema}
          enableReinitialize
          onSubmit={handleUpdatePlanDetails}
        >
          {({ values, handleSubmit, handleBlur, setFieldValue }) => (
            <Form>
              <ScrollToFieldError />
              {!initialValues.hours_per_week ? (
                <TextInput
                  id="hours_per_week"
                  name="hours_per_week"
                  label="Working hours per week *"
                  trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
                  value={values.hours_per_week || ""}
                  isValidValue={(e) => {
                    return (
                      e?.target?.value > 0 &&
                      e?.target?.value <= 40 &&
                      floatRegex.test(parseFloat(e?.target?.value))
                    );
                  }}
                  handleChangeValue={(e, isValidValue) => {
                    setFieldValue(
                      "hours_per_week",
                      isValidValue ? e?.target?.value : ""
                    );
                  }}
                  handleBlurValue={handleBlur}
                />
              ) : null}
              {!initialValues.annual_salary ? (
                <IconInputField
                  id="annual_salary"
                  name="annual_salary"
                  label="How much do you get paid annually? *"
                  trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
                  value={values.annual_salary || ""}
                  icon={<CurrencyPoundIcon className="input-field-icon" />}
                  isValidValue={(e) => {
                    return (
                      e?.target?.value > 0 &&
                      e?.target?.value?.length <= 9 &&
                      floatRegex.test(parseFloat(e?.target?.value))
                    );
                  }}
                  handleChangeValue={(e, isValidValue) => {
                    setFieldValue(
                      "annual_salary",
                      isValidValue ? e?.target?.value : ""
                    );
                  }}
                  handleBlurValue={handleBlur}
                />
              ) : null}
              {!initialValues.date_of_birth ? (
                <DateInput
                  id="date_of_birth"
                  name="date_of_birth"
                  label="Date of Birth *"
                  trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
                  value={values.date_of_birth || null}
                  handleChangeValue={(value) =>
                    setFieldValue(
                      "date_of_birth",
                      moment(value).format("YYYY-MM-DD")
                    )
                  }
                  handleBlurValue={($event) => handleBlur($event)}
                />
              ) : null}
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <IconButton
                  trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
                  buttonTitle="Back"
                  styleClass="light-backbtn"
                  handleClick={handleBack}
                  disabled={isLoading}
                  link="#"
                  icon={<KeyboardArrowLeftIcon />}
                />
                <LoadingButton
                  buttonTitle="Save"
                  type="submit"
                  disabled={isLoading}
                  loading={isLoading}
                  trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
                  styleClass="btn primary-clr-btn mediumbtn mt-30 mb-30"
                  handleClick={handleSubmit}
                />
              </Stack>
            </Form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
};

RequiredDetailsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  initialValues: PropTypes.object,
  handleBack: PropTypes.func,
};

export default RequiredDetailsDialog;
